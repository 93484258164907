.billingTabs{
	background:  #ddd;
}

.billingTabs .Mui-selected{
	background:  #0046af;
}

.billingcontainer .MuiBox-root{
	padding:  0;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  background: green !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  padding: 7px 10px;
}

.invbtn{
	margin-top: 0px;
	font-size: 12px;
	padding: 10px 20px;
	display: block;
}
