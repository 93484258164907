#profilePage p {
	margin-bottom: 7px;
}

#profilePage .MuiGrid-item{
	padding-top: 10px;
}

#profilePage .MuiTypography-root{
	margin-bottom: 20px;
}

#profilePage input::placeholder{
	color: #000 !important;
	opacity: 1 !important;
}

.Mui-focused.MuiOutlinedInput-root.MuiInputBase-root {
	border: 1px solid #aaa;
}

#profilePage .mui-phone .css-pwdk37-MuiInputBase-root-MuiInput-root:before {
	border-bottom: none;
}