@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

body {
	overflow: hidden;
	font-family: 'Space Grotesk', sans-serif;
}

.popper{
	z-index: 99999 !Important;
}

.MuiContainer-root button:hover{
	background: #0046AF !important;
	color: #fff !important;
}

.MuiDialog-root button:hover{
	background: darkblue !important;
	color: #fff !important;
}

.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MUIDataTableToolbar-actions.MUIDataTableToolbar-iconActive{
    color: #0046AF !important;
}

/*Drawer Styles*/
.MuiDrawer-paper{
	background: #0046AF !important;
	color: #ffffff !important;
}

.MuiToolbar-root{
	background-color: #ffffff;
}

.MuiCollapse-wrapperInner .MuiList-root{
	background: #000 !important;
}

.MuiDrawer-docked .MuiListItemIcon-root {
	padding: 20px 20px 25px 20px;
	margin: -16px 15px -16px -16px;
	color: rgba(255, 255, 255, 0.7) !important;
}

.MuiDrawer-docked .MuiListItemText-primary {
	font-size: 14px !important;
}

.MuiDrawer-docked .MuiCollapse-vertical .MuiListItemText-primary {
	text-align: right;
}

.MuiDataGrid-editInputCell input{
	border: 1px solid #aaa;
}

.MuiDataGrid-toolbarContainer{
	background: #0046AF !important;
}

.MuiPaper-root.MuiPopover-paper {
	box-shadow: none;
	border: 1px solid #ccc;
}

/*MUI Datatables Styling*/
.muidatatable td{
	padding-top: 7px !important;
	padding-bottom: 7px !important;
}

@media only screen and (max-width: 600px) {
.authpage .bgpage{
	display: none;
}
}
