.invpopover .MuiListItem-root{
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.invpopover .MuiListItem-root:hover{
	background: #eee !important;
}

.invpopover div span{
	font-size: 12px !important;
	font-weight: bold;
}

.issueprod p{
	margin-bottom: 0 !important;
}

.issueprod input, .issueprod .MuiSelect-select, .MuiOutlinedInput-input{
	/*padding: 4px 10px !important;*/
	border: 1px solid #ddd;
}

.addproduct .MuiOutlinedInput-input, .addproduct input, .addproduct .MuiSelect-select{
	background: #fff !important;
}

.TReaction p{
margin-bottom: 0 !important;
margin-top: 5px !important;
font-size: 0.8em !important;
}

.TReaction .MuiGrid-root{
	padding-top: 0px !important;
	padding-bottom: 0 !important;
}

.TReaction .MuiInputBase-input{
	padding: 10px 14px !important;
}

span.MUIDataTableHeadCell-contentWrapper .MUIDataTableHeadCell-toolButton {
	max-width: 50px !important;
}