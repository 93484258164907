.count {
	background: #0046AF;
	transition: all .2s ease-in-out;
	color: #fff;
	transform: scale(1.1);
	padding: 7px 12px;
	border-radius: 100% 100%;
}


tr .MuiTableCell-head {
	padding: 10px 10px;
}
