.MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
}

.invpopover .MuiListItem-root{
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.invpopover .MuiListItem-root:hover{
	background: #eee !important;
}

.invpopover div span{
	font-size: 12px !important;
	font-weight: bold;
}

.issueprod p{
	margin-bottom: 0 !important;
}

.issueprod input, .issueprod .MuiSelect-select, .MuiOutlinedInput-input{
	/*padding: 4px 10px !important;*/
	border: 1px solid #ddd;
}

.issueprod input:focus, .issueprod .MuiSelect-select:focus{
	/*border: 1px solid #ddd !important;*/
}

.addproduct .MuiOutlinedInput-input, .addproduct input, .addproduct .MuiSelect-select{
	background: #fff !important;
}

.MuiButtonBase-root-MuiRadio-root.Mui-checked{
	color: #000000 !important;
}